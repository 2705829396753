import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

const Settings = () => {
  const { user } = useAuth();
  const [settings, setSettings] = useState({
    cardmarket_id: "",
    cardmarket_password: "",
    ebay_api_key: "",
    csv_format: "",
    price_percentage: 100
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Récupère les paramètres au chargement
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch('https://api.hitscan.app/api/user/settings', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des paramètres');
        }

        const data = await response.json();
        setSettings(data || {
          cardmarket_id: "",
          cardmarket_password: "",
          ebay_api_key: "",
          csv_format: "",
          price_percentage: 100
        });
      } catch (err) {
        setError("Erreur lors de la récupération des paramètres");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  // Gère les changements dans les champs
  const handleChange = (e) => {
    const { id, value } = e.target;
    setSettings(prev => ({
      ...prev,
      [id]: value
    }));
  };

  // Envoie les paramètres au serveur
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      const response = await fetch('https://api.hitscan.app/api/user/settings', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        credentials: 'include',
        body: JSON.stringify(settings)
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la sauvegarde des paramètres');
      }

      setSuccess("Paramètres sauvegardés avec succès!");
    } catch (err) {
      setError("Erreur lors de la sauvegarde des paramètres");
      console.error(err);
    }
  };

  if (loading) {
    return <div className="text-center">Chargement...</div>;
  }

  return (
    <div className="bg-app-bg min-h-screen flex items-center justify-center p-4 py-16">
      <div className="bg-container-bg p-8 rounded-xl shadow-lg w-full max-w-lg">
        <h2 className="text-3xl font-bold mb-6 text-center">Settings</h2>
        
        {error && (
          <div className="bg-red-500 text-white p-3 rounded mb-4">
            {error}
          </div>
        )}
        
        {success && (
          <div className="bg-green-500 text-white p-3 rounded mb-4">
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Card Market ID */}
          <div>
            <label className="block text-lg font-medium mb-2" htmlFor="cardmarket_id">
              Card Market ID
            </label>
            <input
              id="cardmarket_id"
              type="text"
              value={settings.cardmarket_id}
              onChange={handleChange}
              placeholder="Enter your Card Market ID"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200 focus:outline-none bg-container-bg"
            />
          </div>

          {/* Card Market Password */}
          <div>
            <label className="block text-lg font-medium mb-2" htmlFor="cardmarket_password">
              Card Market Password
            </label>
            <input
              id="cardmarket_password"
              type="password"
              value={settings.cardmarket_password}
              onChange={handleChange}
              placeholder="Enter your Card Market Password"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200 focus:outline-none bg-container-bg"
            />
          </div>

          {/* eBay API Key */}
          <div>
            <label className="block text-lg font-medium mb-2" htmlFor="ebay_api_key">
              eBay API Key
            </label>
            <input
              id="ebay_api_key"
              type="text"
              value={settings.ebay_api_key}
              onChange={handleChange}
              placeholder="Enter your eBay API Key"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200 focus:outline-none bg-container-bg"
            />
          </div>

          {/* CSV Format */}
          <div>
            <label className="block text-lg font-medium mb-2" htmlFor="csv_format">
              CSV Format
            </label>
            <input
              id="csv_format"
              type="text"
              value={settings.csv_format}
              onChange={handleChange}
              placeholder="Enter CSV format"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200 focus:outline-none bg-container-bg"
            />
          </div>

          {/* Settings Price % */}
          <div>
            <label className="block text-lg font-medium mb-2" htmlFor="price_percentage">
              Settings Price %
            </label>
            <input
              id="price_percentage"
              type="number"
              value={settings.price_percentage}
              onChange={handleChange}
              placeholder="Enter price percentage"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200 focus:outline-none bg-container-bg"
            />
          </div>

          {/* Save Button */}
          <div className="text-center">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
            >
              Save Settings
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;

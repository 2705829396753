import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsArrowLeftCircle, BsEyeFill } from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";
import { useAuth } from "../../contexts/AuthContext";

const ProxyImage = ({ imageUrl, altText }) => {
  const encodedUrl = encodeURIComponent(imageUrl);

  return (
    <img
      src={`https://api.hitscan.app/proxy/image?url=${encodedUrl}`}
      alt={altText}
      className="w-24 h-auto rounded-md shadow-md object-cover"
    />
  );
};

const Items = ({ title, value }) => (
  <td className="space-y-1 px-4 py-2 text-nowrap text-white ">
    <h3 className=" font-semibold text-[#606060] text-sm uppercase">{title}</h3>
    <p className="text-[#D6D7D7] ">{value}</p>
  </td>
);

const ManageOffers = () => {
  const { user } = useAuth();
  const [offers, setOffers] = useState({
    pokemon_offers: [],
    lorcana_offers: [],
  });
  const [lowestPrices, setLowestPrices] = useState({});
  const [modifiedPrices, setModifiedPrices] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [progress, setProgress] = useState(null);
  const [success, setSuccess] = useState(null);
  const [cardsMetadata, setCardsMetadata] = useState({});

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const response = await fetch('https://api.hitscan.app/api/user/settings', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des paramètres');
        }

        const settings = await response.json();
        setUserSettings(settings);
      } catch (err) {
        setError("Erreur lors de la récupération des paramètres utilisateur");
        console.error(err);
      }
    };

    fetchUserSettings();
  }, []);

  const extractArticleId = (offer) => {
    return offer.id_article;
  };

  const fetchCardMetadata = async (articleId) => {
    try {
      const response = await axios.get(
        `https://api.hitscan.app/api/card/metadata/${articleId}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Erreur lors de la récupération des métadonnées pour l'ID ${articleId}:`, error);
      return null;
    }
  };

  const fetchOffers = async () => {
    setLoading(true);
    setError(null);
    setProgress(null);
    setSuccess(null);

    if (!userSettings?.cardmarket_id || !userSettings?.cardmarket_password) {
      setError("Veuillez configurer vos identifiants Cardmarket dans les paramètres");
      setLoading(false);
      return;
    }

    try {
      setProgress("Connexion à Cardmarket...");
      const response = await axios.get(
        "https://api.hitscan.app/api/cardmarket/offers",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          params: {
            cardmarket_id: userSettings.cardmarket_id,
            cardmarket_password: userSettings.cardmarket_password
          },
          onDownloadProgress: (progressEvent) => {
            const text = progressEvent.event.target.responseText;
            if (text.includes("Obtention de la page de connexion")) {
              setProgress("Obtention de la page de connexion...");
            } else if (text.includes("Envoi des informations de connexion")) {
              setProgress("Envoi des informations de connexion...");
            } else if (text.includes("Connexion réussie !")) {
              setProgress("Connexion réussie !");
            } else if (text.includes("Récupération des cookies")) {
              setProgress("Récupération des cookies...");
            } else if (text.includes("Récupération des offres Pokémon")) {
              setProgress("Récupération des offres Pokémon...");
            } else if (text.includes("Récupération des offres Lorcana")) {
              setProgress("Récupération des offres Lorcana...");
            }
          }
        }
      );

      const newMetadata = {};
      const metadataPromises = [...response.data.pokemon_offers, ...response.data.lorcana_offers]
        .map(async (offer) => {
          const articleId = extractArticleId(offer);
          if (articleId) {
            const metadata = await fetchCardMetadata(articleId);
            if (metadata) {
              newMetadata[articleId] = metadata;
            }
          }
        });

      await Promise.all(metadataPromises);
      setCardsMetadata(newMetadata);
      setOffers(response.data);
      setSuccess("Offres récupérées avec succès!");
    } catch (err) {
      setError(err.response?.data?.error || "Échec de la récupération des offres.");
      console.error(err);
    } finally {
      setLoading(false);
      setProgress(null);
    }
  };

  const fetchLowestPrices = async (url, condition, language, key) => {
    try {
      const response = await axios.post(
        "https://api.hitscan.app/api/market-prices",
        {
          url: url,
          language: language,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          withCredentials: true
        }
      );

      setLowestPrices((prev) => ({
        ...prev,
        [key]: response.data.prices || {},
      }));
    } catch (err) {
      console.error(`Error fetching prices for ${key}:`, err);
    }
  };

  const fetchAllPrices = async () => {
    setLoading(true);
    setError(null);
    const promises = [];
    const newLowestPrices = {};

    [...offers.pokemon_offers, ...offers.lorcana_offers].forEach(
      (offer, index) => {
        const key = offer.link || `${offer.name}-${index}`;
        promises.push(
          axios
            .post(
              "https://api.hitscan.app/api/market-prices",
              {
                url: offer.link,
                language: offer.language,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                withCredentials: true
              }
            )
            .then((response) => {
              newLowestPrices[key] = response.data.prices || {};
            })
            .catch((err) => {
              console.error(`Error for ${offer.name}:`, err);
            })
        );
      }
    );

    await Promise.all(promises);
    setLowestPrices(newLowestPrices);
    setLoading(false);
  };

  const handlePriceChange = (key, value) => {
    setModifiedPrices((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const renderTable = (offers, title) => (
    <div className="my-6">
      <h2 className="text-xl font-semibold mb-4 text-white">{title}</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto bg-gray-800 shadow-lg rounded-lg ">
          <tbody className="overflow-auto">
            {offers.map((offer, index) => {
              const key = offer.link || `${offer.name}-${index}`;
              const prices = lowestPrices[key] || {};
              const articleId = extractArticleId(offer);
              const metadata = articleId ? cardsMetadata[articleId] : null;

              return (
                <tr
                  key={index}
                  className="border-b border-app-bg bg-container-bg text-nowrap p-6 flex items-center justify-between gap-8"
                >
                  <td className="py-2 px-4 text-nowrap min-w-32 max-w-full">
                    <ProxyImage imageUrl={offer.image} altText={offer.name} />
                  </td>

                  <Items title={"Card Name"} value={offer.name} />
                  <Items 
                    title={"Set Name"} 
                    value={metadata ? metadata.set_name : "Loading..."} 
                  />
                  <Items 
                    title={"Card Number"} 
                    value={metadata ? metadata.local_id : "Loading..."} 
                  />
                  <Items title={"Condition"} value={offer.condition_title} />
                  <Items title={"Language"} value={offer.language} />
                  <Items title={"Marketplace"} value={"Cardmarket"} />

                  <td className="space-y-1 px-4 py-2 text-nowrap text-white">
                    <h3 className="font-semibold text-[#606060] text-sm uppercase">
                      Lowest Price
                    </h3>
                    {Object.entries(prices).map(([condition, priceList], i) => (
                      <p key={i} className="text-white">
                        {condition}: {priceList[0]}€
                      </p>
                    ))}
                    {Object.keys(prices).length === 0 && (
                      <p className="text-gray-400">Pas de prix disponible</p>
                    )}
                  </td>

                  <td className="py-2 px-4 text-nowrap flex flex-col gap-4">
                    <a
                      href={offer.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-[#353434] px-4 py-2 text-white rounded-md shadow-sm text-center flex gap-2 items-center justify-center"
                    >
                      <BsEyeFill className="text-white text-lg" />
                      View Offer
                    </a>
                    <button
                      onClick={() => fetchLowestPrices(offer.link, offer.condition_title, offer.language, key)}
                      className="px-4 py-2 bg-[#0C6FFF] text-white rounded-md shadow-sm flex gap-2 items-center justify-center"
                    >
                      <BiRefresh className="text-white text-xl" />
                      Reload Price
                    </button>
                    <button className="px-4 py-2 bg-[#9900C2] text-white rounded-md shadow-sm flex gap-2 items-center justify-center">
                      <BsArrowLeftCircle className="text-white text-lg" />
                      Update Offer
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className=" px-4 sm:px-6 lg:px-8 py-6 bg-app-bg rounded-3xl">
      <h1 className="text-2xl font-bold text-white mb-4">
        My Cardmarket Offers
      </h1>
      
      {!userSettings?.cardmarket_id && (
        <div className="bg-yellow-500 text-white p-3 rounded mb-4">
          Veuillez configurer vos identifiants Cardmarket dans les 
          <a href="/settings" className="underline ml-1">paramètres</a>
        </div>
      )}

      {/* Messages de progression */}
      {progress && (
        <div className="bg-blue-500 text-white p-3 rounded mb-4 flex items-center">
          <div className="animate-spin mr-3">
            <BiRefresh className="text-xl" />
          </div>
          {progress}
        </div>
      )}

      {/* Message de succès */}
      {success && (
        <div className="bg-green-500 text-white p-3 rounded mb-4">
          {success}
        </div>
      )}

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-500 text-white p-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="mb-6 flex gap-4">
        <button
          onClick={fetchOffers}
          disabled={loading || !userSettings?.cardmarket_id}
          className={`px-4 py-2 rounded-md shadow-sm flex items-center gap-2 ${
            loading || !userSettings?.cardmarket_id
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-green-500 hover:bg-green-600 text-white"
          }`}
        >
          {loading && <BiRefresh className="animate-spin" />}
          {loading ? "Chargement..." : "Charger les offres"}
        </button>
        
        <button
          onClick={fetchAllPrices}
          disabled={loading}
          className={`px-4 py-2 rounded-md shadow-sm flex items-center gap-2 ${
            loading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600 text-white"
          }`}
        >
          {loading && <BiRefresh className="animate-spin" />}
          {loading ? "Chargement..." : "Récupérer tous les prix"}
        </button>
      </div>

      {/* Tables des offres */}
      {offers.pokemon_offers.length > 0 &&
        renderTable(offers.pokemon_offers, "Pokémon")}
      {offers.lorcana_offers.length > 0 &&
        renderTable(offers.lorcana_offers, "Lorcana")}
    </div>
  );
};

export default ManageOffers;

const CardResult = ({
  result,
  marketPrices,
  product,
  onSelectProduct,
  onSell,
}) => {
  console.log("Inside the CardResult component");
  console.log(result);
  return (
    <div className="bg-gray-800 rounded-lg p-4 flex gap-4">
      {/* Images */}
      <div className="flex gap-2">
        <img
          src={`https://api.hitscan.app/media/${result.uploaded_image
            .split("/")
            .pop()}`}
          alt="Card Source"
          className="w-28 rounded-lg"
        />
        <img
          src={`https://api.hitscan.app${result.found_image_path}`}
          alt="Card Results"
          className="w-28 rounded-lg"
        />
      </div>

      {/* Card Details */}
      <div className="flex-1">
        <div className="grid grid-cols-2 gap-4">
          {/* Card Name */}
          <div className="col-span-2">
            <p className="text-sm text-gray-400">CARD NAME</p>
            <h3 className="text-lg text-white">{result.details.data.name}</h3>
          </div>

          {/* Set Name */}
          <div>
            <p className="text-sm text-gray-400">SET NAME</p>
            <p className="text-white">{result.details.data.setName}</p>
          </div>

          {/* Card Number */}
          <div>
            <p className="text-sm text-gray-400">CARD NUMBER</p>
            <p className="text-white">{result.details.data.fullIdentifier}</p>
          </div>

          {/* Condition Dropdown */}
          <div>
            <p className="text-sm text-gray-400">CONDITION</p>
            <select
              className="w-full bg-gray-700 text-sm text-white py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={product.condition || "NM"}
              onChange={(e) => onSelectProduct("condition", e.target.value)}
            >
              <option value="M">Mint</option>
              <option value="NM">Near Mint</option>
              <option value="EX">Excellent</option>
              <option value="LP">Light Played</option>
              <option value="PL">Played</option>
            </select>
          </div>

          {/* Language Dropdown */}
          <div>
            <p className="text-sm text-gray-400">LANGUAGE</p>
            <select
              className="w-full bg-gray-700 text-sm text-white py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={product.language || "Français"}
              onChange={(e) => onSelectProduct("language", e.target.value)}
            >
              <option value="Français">Français</option>
              <option value="Anglais">Anglais</option>
              <option value="Allemand">Allemand</option>
            </select>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-2 mt-4">
          <input
            type="number"
            value={product.price || ""}
            onChange={(e) => onSelectProduct("price", e.target.value)}
            placeholder="Set Price (€)"
            className="w-28 bg-gray-700 text-sm text-white py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={onSell}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            + Cardmarket
          </button>
          <button className="bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600">
            Add to Collection
          </button>
          <button className="bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600">
            Export to .CSV
          </button>
        </div>
      </div>

      {/* Market Prices */}
      <div className="w-48">
        {marketPrices &&
          Object.entries(marketPrices).map(([condition, prices]) => (
            <div
              key={condition}
              className="flex justify-between items-center bg-gray-700 py-2 px-3 mb-2 rounded"
            >
              <span className="text-sm text-white">{condition}</span>
              <span className="text-sm text-white">
                {Array.isArray(prices) ? prices[0] : prices}€
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CardResult;

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import Upload from "./components/Upload";
import Header from "./components/Header";
import ManageOffers from "./components/ManageOffers";
import Login from "./components/Auth/Login";
import Settings from "./components/Settings";

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!user) {
    // Sauvegarde l'URL que l'utilisateur essayait d'atteindre
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const Layout = ({ children }) => {
  return (
    <div className="bg-container-bg rounded-[3rem]">
      <Header />
      <div className="p-5">
        {children}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-app-bg text-white">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Layout>
                    <Upload />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/manage"
              element={
                <PrivateRoute>
                  <Layout>
                    <ManageOffers />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Layout>
                    <Settings />
                  </Layout>
                </PrivateRoute>
              }
            />
            {/* Route par défaut - redirige vers la page d'accueil */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import { io } from "socket.io-client";
import axios from "axios";
import { FaSearch, FaTh, FaList } from "react-icons/fa";
import CardResult from "./CardResult";
import ResponsiveContainer from "../ResponsiveContainer";
import ListCard from "../Cards/ListCard";
import GridCard from "../Cards/GridCard";
import { BiBox } from "react-icons/bi";
import { GrDocument } from "react-icons/gr";

// Connect to the server via Socket.io
const socket = io("https://api.hitscan.app", {
  transports: ["websocket", "polling"],
  cors: {
    origin: "http://localhost:3000",
    methods: ["GET", "POST"],
    credentials: true,
  },
});

const Upload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [results, setResults] = useState([]);
  const [marketPrices, setMarketPrices] = useState({});
  const [selectedProducts, setSelectedProducts] = useState({});
  const [processingStatus, setProcessingStatus] = useState({});
  const [lastScannedCard, setLastScannedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [socketId, setSocketId] = useState(null);
  const [view, setView] = useState("List");

  // Handle socket events for processing updates
  useEffect(() => {
    // Reconnecter le socket si nécessaire
    if (!socket.connected) {
      socket.connect();
    }

    // Récupérer le socket ID stocké
    const storedSocketId = localStorage.getItem('socketId');
    if (storedSocketId) {
      setSocketId(storedSocketId);
    }

    socket.on("connect", () => {
      console.log("Connected to server with ID:", socket.id);
      setSocketId(socket.id);
      localStorage.setItem('socketId', socket.id);
      
      // Envoyer le nouveau socket ID au serveur
      axios.post("https://api.hitscan.app/api/set-socket-id", {
        sid: socket.id
      }, {
        withCredentials: true
      });
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
      // Tentative de reconnexion immédiate
      socket.connect();
    });

    socket.on("upload_received", (data) => {
      setProcessingStatus((prev) => ({
        ...prev,
        [data.filename]: "Processing...",
      }));
    });

    socket.on("processing_complete", async (result) => {
      if (result.error) {
        setProcessingStatus((prev) => ({
          ...prev,
          [result.original_path]: "Error",
        }));
      } else {
        setResults((prev) => {
          return [...prev, {
            ...result,
            original_path: result.uploaded_image
          }];
        });

        const productId = result.details.data.id;
        setSelectedProducts((prev) => ({
          ...prev,
          [productId]: {
            price: "",
            condition: "NM",
            language: "Français",
            selected: false,
          },
        }));

        // Fetch prices automatically
        try {
          let { cardmarketUrl } = result.details.data;
          if (cardmarketUrl) {
            cardmarketUrl = cardmarketUrl.replace(
              "www.cardmarket.com",
              "85.215.9.86"
            );
            const response = await axios.post(
              "https://api.hitscan.app/api/market-prices",
              {
                url: cardmarketUrl,
                language: "Français",
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                withCredentials: true
              }
            );
            setMarketPrices((prev) => ({
              ...prev,
              [productId]: response.data.prices || {},
            }));
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des prix :", error);
        }

        setProcessingStatus((prev) => ({
          ...prev,
          [result.original_path]: "Complete",
        }));

        setLastScannedCard(result.found_image_base64);
      }
    });

    // Cleanup function
    return () => {
      socket.off("connect");
      socket.off("upload_received");
      socket.off("processing_complete");
      // Ne pas déconnecter le socket ici
    };
  }, []); // Empty dependency array

  // Ajouter un effet pour gérer la reconnexion lors du retour sur la page
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (!socket.connected) {
          console.log("Page visible, tentative de reconnexion");
          socket.connect();
        }
        // Récupérer et réutiliser le dernier socket ID connu
        const storedSocketId = localStorage.getItem('socketId');
        if (storedSocketId) {
          setSocketId(storedSocketId);
          // Réenvoyer le socket ID au serveur
          axios.post("https://api.hitscan.app/api/set-socket-id", {
            sid: storedSocketId
          }, {
            withCredentials: true
          });
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
    setResults([]);
    setProcessingStatus({});
  };

  // Upload files to the server
  const handleUpload = async () => {
    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => {
      formData.append("files[]", file);
    });

    setLoading(true);
    try {
      await axios.post("https://api.hitscan.app/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Socket-ID": socketId, // Use the socket ID from state
        },
      });
    } catch (error) {
      console.error("Erreur lors de l'upload :", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle selection of product details
  const handleSelectProduct = (productId, field, value) => {
    setSelectedProducts((prev) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        [field]: value,
      },
    }));
  };

  // Sell a product
  const handleSell = async (productId) => {
    const product = selectedProducts[productId];
    if (!product) return;

    const productDetails = results.find(
      (result) => result.details.data.id === productId
    );
    const cardmarketId = productDetails?.details?.data?.cardmarketId;
    const fullIdentifier = productDetails?.details?.data?.fullIdentifier;
    const cardCountTotal = productDetails?.details?.data?.cardCountTotal;

    if (!cardmarketId) {
      alert("Erreur : CardMarket ID introuvable.");
      return;
    }

    try {
      const response = await axios.post("https://api.hitscan.app/api/sell", {
        id: cardmarketId,
        price: product.price,
        condition: product.condition,
        language: product.language,
        fullIdentifier,
        cardCountTotal,
      });
      alert(`Produit mis en vente : ${response.data.message}`);
    } catch (error) {
      console.error("Erreur lors de la mise en vente :", error);
      alert("Erreur lors de la mise en vente.");
    }
  };

  return (
    <ResponsiveContainer>
      {/* Left Panel - Upload Zone */}
      <div className="bg-container-bg p-4 py-14 text-center space-y-10 h-auto xl:h-min min-h-[80vh] shadow-lg flex-[0.9] border-gray-500 border-2 border-dashed  rounded-lg items-center flex flex-col justify-center ">
        {lastScannedCard && (
          <div className=" border-b pb-8">
            <p>Last card Scanned</p>
            <img
              src={`data:image/jpeg;base64,${lastScannedCard}`}
              alt="Last scanned"
              className="w-[80%] object-cover m-auto mt-4 rounded-lg"
            />
          </div>
        )}
        <div className="flex flex-col items-center">
          <p className="text-gray-400 text-center mb-4">
            Choose a file or <br />
            Drag & Drop an image here
          </p>
          <label
            htmlFor="file-input"
            className="px-6 py-4 text-sm font-semibold text-blue-500 border border-blue-500 rounded hover:bg-blue-500 hover:text-white"
          >
            Browse files
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              hidden
              id="file-input"
            />
          </label>

          {selectedFiles.length > 0 && (
            <button
              onClick={handleUpload}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {loading
                ? "Uploading..."
                : `Upload ${selectedFiles.length} files`}
            </button>
          )}
        </div>
      </div>

      {/* Right Panel - Results */}
      <div className="flex-[2.8] flex flex-col gap-6">
        {results.length === 0 ? (
          <div className="rounded-lg">
            <div className="flex justify-between items-center mb-6">
              <p className="text-gray-400">No cards uploaded yet</p>
              <div className="flex items-center gap-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search a product"
                    className="bg-transparent text-md accent:app-bg pl-12 pr-4 text-white py-2 focus:outline-none border-r-2 w-52 border-container-bg"
                  />
                  <FaSearch className="absolute text-lg left-3 top-1/2 transform -translate-y-1/2 rotate-90 text-gray-500" />
                </div>
                <p className="text-gray-400">Grid View</p>
                <div className="flex gap-2">
                  <button className="bg-gray-700 p-2 rounded text-white hover:bg-gray-600">
                    <FaTh />
                  </button>
                  <button className="bg-gray-800 p-2 rounded text-gray-500 hover:bg-gray-700 hover:text-white">
                    <FaList />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="rounded-lg">
            <div className="flex justify-between items-center mb-6 flex-wrap space-y-4">
              <p className="text-white text-sm mt-3">
                {results.length} cards uploaded
              </p>

              <div className="flex gap-2 items-center justify-between flex-wrap">
                <button className="bg-[#353535] text-white py-2 px-4 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap flex-1">
                  <BiBox className="text-sm" />
                  <span>Sell on ebay</span>
                </button>
                <button className="bg-[#353535] text-white py-2 px-4 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap flex-1">
                  <BiBox className="text-sm" />
                  <span>Sell on Cardmarket</span>
                </button>
                <button className="bg-[#353535] text-white py-2 px-4 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap flex-1">
                  <GrDocument className="text-sm" />
                  <span className="text-[0.67rem]">Export All to .CSV</span>
                </button>
              </div>

              <div className="flex items-center gap-4 flex-wrap">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search a product"
                    className="bg-transparent text-md accent:app-bg pl-12 pr-4 text-white py-2  focus:outline-none border-r-2 w-52 border-container-bg "
                  />
                  <FaSearch className="absolute text-lg left-3 top-1/2 transform -translate-y-1/2 rotate-90 text-gray-500" />
                </div>
                <p className="text-gray-400">{view} View</p>
                <div className="flex gap-2">
                  <button
                    className={`bg-gray-800 p-2 rounded text-gray-500  hover:text-white ${
                      view === "Grid" ? "bg-gray-600 text-white" : ""
                    }`}
                    onClick={() => {
                      setView("Grid");
                    }}
                  >
                    <FaTh />
                  </button>
                  <button
                    className={`bg-gray-800 p-2 rounded text-gray-500  hover:text-white ${
                      view === "List" ? "bg-gray-600 text-white" : ""
                    }`}
                    onClick={() => {
                      setView("List");
                    }}
                  >
                    <FaList />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {Object.keys(processingStatus).length > 0 && (
          <div className="mb-4">
            {Object.entries(processingStatus).map(([filename, status]) => (
              <div
                key={filename}
                className="flex items-center justify-between text-white mb-2"
              >
                <p>
                  {filename}: {status}
                </p>
                {status === "Processing..." && (
                  <div className="loader h-5 w-5 border-2 border-t-blue-500 rounded-full animate-spin"></div>
                )}
              </div>
            ))}
          </div>
        )} */}

        {view === "List" ? (
          <div className="grid grid-cols-1 gap-4">
            {results?.map((result, index) => (
              <ListCard
                key={index}
                result={result}
                marketPrices={marketPrices[result.details.data.id] || {}}
                product={selectedProducts[result.details.data.id]}
                onSelectProduct={(field, value) =>
                  handleSelectProduct(result.details.data.id, field, value)
                }
                onSell={() => handleSell(result.details.data.id)}
              />
            ))}
          </div>
        ) : (
          <div className="grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
            {results?.map((result, index) => (
              <GridCard
                key={index}
                result={result}
                marketPrices={marketPrices[result.details.data.id] || {}}
                product={selectedProducts[result.details.data.id]}
                onSelectProduct={(field, value) =>
                  handleSelectProduct(result.details.data.id, field, value)
                }
                onSell={() => handleSell(result.details.data.id)}
              />
            ))}
          </div>
        )}
        {/* {results?.map((result, index) => (
          <CardResult
            key={index}
            result={result}
            marketPrices={marketPrices[result.details.data.id] || {}}
            product={selectedProducts[result.details.data.id]}
            onSelectProduct={(field, value) =>
              handleSelectProduct(result.details.data.id, field, value)
            }
            onSell={() => handleSell(result.details.data.id)}
          />
        ))} */}
      </div>
    </ResponsiveContainer>
  );
};

export default Upload;

import React from "react";
import { BiBox } from "react-icons/bi";
import { GrDocument } from "react-icons/gr";
import { LuBookMarked } from "react-icons/lu";
import { RxArrowTopRight } from "react-icons/rx";

const ListCard = ({ result, marketPrices, product, onSelectProduct, onSell }) => {
  const sortedConditions = [
    "Mint",
    "Near Mint",
    "Excellent",
    "Good",
    "Light Played",
    "Played",
    "Poor",
  ];

  return (
    <div className="bg-container-bg rounded-lg p-4 flex flex-col md:flex-row gap-4">
      {/* Images */}
      <div className="flex gap-2">
        <img
          src={`https://api.hitscan.app${result?.original_path}`}
          alt="Card Front"
          className="w-32 h-52 max-w-full rounded-lg object-cover flex-1"
        />
        <img
          src={`https://api.hitscan.app${result?.details?.data?.found_image_path}`}
          alt="Card Back"
          className="w-32 h-52 max-w-full rounded-lg object-cover flex-1"
        />
      </div>

      {/* Card Details */}
      <div className="flex-1">
        <div className="mt-4 md:mt-0">
          <div className="flex gap-8 justify-between flex-wrap">
            {/* Card Information */}
            <div className="space-y-6">
              <CardItemData
                title="CARD NAME"
                value={result?.details?.data?.name || "-"}
              />
              <CardItemData
                title="SET NAME"
                value={result?.details?.data?.setName || "-"}
              />
            </div>

            {/* Card Market Information */}
            <div className="space-y-6">
              <div className="space-y-2">
                <p className="text-[0.6rem] text-[#626262] font-medium">CARD MARKET ID</p>
                <div className="flex items-center gap-2">
                  <a 
                    href={result?.details?.data?.cardmarketUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white hover:text-blue-400 flex items-center gap-2 cursor-pointer"
                  >
                    <span>{result?.details?.data?.cardmarketId || "-"}</span>
                    <RxArrowTopRight className="text-sm" />
                  </a>
                </div>
              </div>
              <CardItemData
                title="CARD NUMBER"
                value={result?.details?.data?.fullIdentifier || "-"}
              />
            </div>

            {/* ESTIMATED PRICE */}
            <div className="space-y-2 flex-[0.8]">
              <p className="text-[0.6rem] text-[#626262] font-medium">
                ESTIMATED PRICE
              </p>
              <div className="space-y-2">
                {sortedConditions.map((condition) => {
                  if (!marketPrices[condition]) return null;
                  const price = marketPrices[condition][0];
                  const maxPrice = Math.max(
                    ...Object.values(marketPrices).map((p) => p[0])
                  );

                  const colors = {
                    "Mint": "#44C374",
                    "Near Mint": "#0A97B0",
                    "Excellent": "#44C374",
                    "Good": "#FF824D",
                    "Light Played": "#F9C0AB",
                    "Played": "#FFDB4D",
                    "Poor": "#AB4459",
                  };

                  return (
                    <div
                      key={condition}
                      className="flex items-center justify-between gap-2"
                    >
                      <div
                        className="py-[0.35rem] px-2 text-xs rounded flex items-center"
                        style={{
                          width: `${(price / maxPrice) * 100}%`,
                          backgroundColor: `${colors[condition]}20`,
                          color: `${colors[condition]}`,
                        }}
                      >
                        {condition}
                      </div>
                      <p className="text-xs">{price} €</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="mt-4 flex gap-2 flex-wrap">
          <button className="bg-[#353535] text-white py-2 px-2 rounded-lg text-[0.67rem] gap-1 flex justify-center items-center whitespace-nowrap flex-1">
            <span className="text-[0.65rem]">Set Price:</span>
            <input
              type="number"
              value={product.price || ""}
              onChange={(e) => onSelectProduct("price", e.target.value)}
              placeholder="Set Price"
              className="w-16 bg-gray-700 text-[0.65rem] text-white py-1 px-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 flex-1"
            />
          </button>
          <button className="bg-[#353535] text-white py-2 px-4 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap">
            <BiBox className="text-sm" />
            <span>+ Ebay</span>
          </button>
          <button
            onClick={onSell}
            className="bg-[#353535] text-white py-2 px-2 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap flex-1"
          >
            <BiBox className="text-sm" />
            <span>+ Cardmarket</span>
          </button>
          <button className="bg-[#353535] text-white py-2 px-2 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap flex-1">
            <LuBookMarked className="text-sm" />
            <span>Add to collection</span>
          </button>
          <button className="bg-[#353535] text-white py-2 px-2 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap flex-1">
            <GrDocument className="text-sm" />
            <span className="text-[0.67rem]">Export to .CSV</span>
          </button>
        </div>
      </div>
    </div>
  );
};

// Component for displaying card information
export const CardItemData = ({ title, value }) => {
  return (
    <div>
      <p className="text-[0.6rem] text-[#626262] font-medium">{title}</p>
      <h3 className="text-[0.82rem] font-light text-white mt-1 flex items-center gap-1">
        {value}
        {title === "CARD MARKET ID" ? <RxArrowTopRight /> : ""}
      </h3>
    </div>
  );
};

export default ListCard;

import React from "react";
import { BiBox } from "react-icons/bi";
import { GrDocument } from "react-icons/gr";
import { LuBookMarked } from "react-icons/lu";
import { RxArrowTopRight } from "react-icons/rx";

const GridCard = ({
  result,
  marketPrices,
  product,
  onSelectProduct,
  onSell,
}) => {
  return (
    <div className="bg-container-bg rounded-lg p-4">
      {/* Images */}
      <div className="flex gap-2">
        <img
          src={`https://api.hitscan.app${result?.original_path}`}
          alt="Card Front"
          className="w-32 h-auto max-w-full rounded-lg object-cover flex-1"
        />
        <img
          src={`https://api.hitscan.app${result?.details?.data?.found_image_path}`}
          alt="Card Back"
          className="w-32 h-auto max-w-full rounded-lg object-cover flex-1"
        />
      </div>
      {/* Card Details */}
      <div>
        <div className="mt-4 space-y-2">
          <button className="bg-[#353535] text-white py-2 px-4 rounded-lg text-[0.67rem] gap-4 flex justify-center items-center whitespace-nowrap w-full">
            <span>Set Price (€) : </span>
            <input
              type="number"
              value={product.price || ""}
              onChange={(e) => onSelectProduct("price", e.target.value)}
              placeholder="Set Price "
              className=" bg-gray-700 text-[0.65rem] text-white py-1 px-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </button>
          <div className="flex gap-2">
            <button className="bg-[#353535] text-white py-2 px-4 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap flex-1 flex-wrap">
              <BiBox className="text-sm" />
              <span>+ Ebay</span>
            </button>
            <button className="bg-[#353535] text-white py-2 px-4 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap flex-1">
              <BiBox className="text-sm" />
              <span>+ Cardmarket</span>
            </button>
          </div>
          <button className="bg-[#353535] text-white py-2 px-4 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap w-full">
            <LuBookMarked className="text-sm" />
            <span>Add to collection</span>
          </button>
          <button className="bg-[#353535] text-white py-2 px-4 rounded-lg text-[0.67rem] gap-2 flex justify-center items-center whitespace-nowrap w-full">
            <GrDocument className="text-sm" />
            <span className="text-[0.67rem]">Export to .CSV</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GridCard;

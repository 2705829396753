import React, { useState } from "react";
import NavItems from "./NavItems";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="flex  relative md:flex-row items-center gap-4 md:gap-8 justify-between px-4 md:px-6 py-3 ">
      <div className="flex items-center justify-between w-auto ">
        <div className="flex items-center justify-between ">
          <div className="xl:hidden mr-4">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isMenuOpen
                      ? "M6 18L18 6M6 6l12 12"
                      : "M4 6h16M4 12h16m-7 6h7"
                  }
                ></path>
              </svg>
            </button>
          </div>
          <img
            src="/logo.png"
            alt="HitScan"
            className="block object-contain w-24 md:w-auto"
          />
        </div>
      </div>

      <div
        className={`xl:flex ${
          isMenuOpen
            ? "flex-row xl:flex-col absolute xl:relative top-full z-20 left-0 right-0 "
            : "hidden"
        } w-full 2xl:w-auto `}
      >
        <NavItems />
      </div>

      <div className="hidden xl:flex flex-col md:flex-row items-center gap-2 md:gap-4">
        <div className="flex items-center bg-container-bg p-3 rounded">
          <div className="flex flex-col items-start">
            <span className="text-lg font-semibold bg-gradient-to-r from-[#FFEF4F] to-[#D6B12E] bg-clip-text text-transparent">
              Premium
            </span>
            <span className="text-sm text-gray-400">Unlimited Tokens</span>
          </div>
          <img src="/coin.png" alt="Coin" className="w-6 h-6 ml-2" />
        </div>
        {user ? (
          <div className="flex items-center gap-2 bg-container-bg p-3 rounded">
            <span className="text-white">{user.email}</span>
            <button
              onClick={logout}
              className="w-8 h-8 bg-gray-700 text-white flex items-center justify-center rounded-full"
            >
              {user.email.charAt(0).toUpperCase()}
            </button>
          </div>
        ) : (
          <button
            onClick={() => navigate("/login")}
            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
          >
            Login
          </button>
        )}
      </div>

      <div className="xl:hidden relative">
        <button
          onClick={toggleDropdown}
          className="flex items-center gap-2 bg-container-bg p-3 rounded"
        >
          <span className="text-white">Mrsachou</span>
          <div className="w-8 h-8 bg-gray-700 text-white flex items-center justify-center rounded-full">
            L
          </div>
        </button>
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-container-bg p-3 rounded shadow-lg">
            <div className="flex items-center bg-container-bg p-3 rounded">
              <div className="flex flex-col items-start">
                <span className="text-lg font-semibold bg-gradient-to-r from-[#FFEF4F] to-[#D6B12E] bg-clip-text text-transparent">
                  Premium
                </span>
                <span className="text-sm text-gray-400">Unlimited Tokens</span>
              </div>
              <img src="/coin.png" alt="Coin" className="w-6 h-6 ml-2" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
